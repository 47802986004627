import React, { useState } from "react";

import { NavLink } from "react-router-dom";
import { useTranslation, Trans, setI18n } from "react-i18next";
import { useCookies } from "react-cookie";
import "./Navigation.css";
import "./Header.css";

function Header({ onClick }) {
  const { t, i18n } = useTranslation();
  const [cookies, setCookie] = useCookies(["i18n"]);

  function setI18n(lng) {
    i18n.changeLanguage(lng);
    setCookie("i18n", lng);
  }
  const lngs = {
    en: { nativeName: "EN" },
    gr: { nativeName: "Ελ" },
  };
  return (
    <div id="header" className="header flexbox">
      <NavLink exact to="/">
        <img
          src="./static/logo/png/logo.png"
          className="header--logo"
          height="33"
          alt="Blue Oyster: PADI Diving center and underwarter services in Athens"
        />
      </NavLink>
      <span className="flexbox--spacer"></span>
      <div className="navigation-lang">
        {Object.keys(lngs).map((lng) => (
          <button
            className={`navigation-lang--button ${
              i18n.resolvedLanguage === lng ? "active" : ""
            }`}
            key={lng}
            type="submit"
            onClick={() => setI18n(lng)}
          >
            {lngs[lng].nativeName}
          </button>
        ))}
      </div>
    </div>
  );
}

export default Header;
