import React, { useState, useEffect } from "react";

import {
  BrowserRouter as Router,
  Switch,
  Route,
  NavLink,
  Redirect,
} from "react-router-dom";
import "./Contact.css";
import { HashLink as Link } from "react-router-hash-link";

import hashScroll from "./hashscroll";
import axios from "axios";
import { Helmet } from "react-helmet";
import {
  FaRegEnvelope,
  FaPhoneAlt,
  FaInstagram,
  FaExternalLinkAlt,
} from "react-icons/fa";
function Contact() {
  const API_PATH = window.location.origin + "/mail.php";
  // https://resend.com/docs/send-with-nodejs
  // https://resend.com/docs/dashboard/domains/introduction
  // //"http://localhost:8080/mail.php";
  // (process.env.NODE_ENV === "development"
  // 	? "http://localhost:8080/"
  // 	: "https://gosocial.de/") + "mail.php";
  // const [fname, setFname] = useState("");
  // const [lname, setLname] = useState("");
  // const [email, setEmail] = useState("");
  // const [message, setMessage] = useState("");
  const [mailSent, setMailSent] = useState(false);
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [errorMessageEmail, setErrorMessageEmail] = useState(null);
  const [errorMessageSend, setErrorMessageSend] = useState(null);
  const [formData, setFormData] = useState({ email: "", lname: "", fname: "" });

  const handleFormSubmit = (e) => {
    e.preventDefault();
    console.log(
      formData["email"].length,
      formData["email"].length < 3,
      !new RegExp("@").test(formData["email"]),
      ">>",
      formData["email"].length < 3 || !new RegExp("@").test(formData["email"])
    );
    if (
      formData["email"].length < 3 ||
      !new RegExp("@").test(formData["email"])
    ) {
      console.log("HASERRROR");
      setErrorMessageEmail("Bitte gib eine E-Mail Adresse an!");
      setError(true);
      return false;
    } else {
      setErrorMessageEmail("");
      setError(false);
    }
    axios({
      method: "post",
      url: `${API_PATH}`,
      headers: { "content-type": "application/json" },
      data: JSON.stringify(formData),
    })
      .then((result) => {
        console.log("result", result);
        if (result.data.sent) {
          setErrorMessageSend("");
          setError(false);
          setSuccessMessage(
            "Vielen Dank für die Nachricht. Wir werden uns umgehend melden!"
          );
        } else {
          setErrorMessageSend(
            "Leider konnte die E-Mail nicht verschickt werden."
          );
          setError(true);
        }
      })
      .catch((error) => {
        console.log("error", error);
        setError(true);
        setErrorMessageSend(
          "Leider konnte die E-Mail nicht verschickt werden."
        );
      });
  };
  const handleChange = (e, field) => {
    let value = e.target.value;
    formData[field] = value;
    setFormData(formData);
  };
  useEffect(() => {
    hashScroll();
  });

  return (
    <div id="contact">
      <Helmet>
        <title>
          BLUE OYSTER DIVING CENTER ATHENS. PADI COURSES & MARINE DEPARTMENT.
        </title>
        <meta
          name="description"
          content="PADI certification, fun diving and experienced marine department. We have you covered."
        />
        <meta
          property="og:title"
          content="BLUE OYSTER DIVING CENTER ATHENS. PADI COURSES & MARINE DEPARTMENT."
        />
        <meta
          property="og:description"
          content="PADI certification, fun diving and experienced marine department. We have you covered."
        />
      </Helmet>
      {/* <div className="view--item view--hero">
				<div className="view--item__content">
					<h1>Kontakt</h1>
				</div>
			</div> */}
      <div
        className="view--item view--cover view--bg-300"
        style={{
          backgroundImage: "url('/static/images/contact-1.jpg')",
        }}
      >
        <div className="view--item__content view--item ">
          <div className="view--item__home-teaser">
            <h1>
              CONTACT US
              <span className="view--item__subline">
                <a href="tel:00306972295891">
                  <FaPhoneAlt />
                  (+30) 306972295891
                </a>
                <br />
                <a href="https://www.instagram.com/blueoysterdive">
                  <FaInstagram />
                  @blueoysterdive
                </a>
              </span>
            </h1>
          </div>
        </div>
      </div>

      {/* <div className="view--item view--medium">
        <div className="view--item__content">
          <h1>E-Mail</h1>
          {error && (
            //display an error here
            <h2>
              {errorMessageEmail}
              <br />
              {errorMessageSend}
            </h2>
          )}

          {successMessage && (
            //display an error here
            <h2>{successMessage}</h2>
          )}
          <form action="/mail.php" id="contact-form">
            <label>Vorname</label>
            <input
              onChange={(e) => handleChange(e, "fname")}
              type="text"
              id="fname"
              name="firstname"
              placeholder="Vorname"
            />
            <label>Last Name</label>
            <input
              onChange={(e) => handleChange(e, "lname")}
              type="text"
              id="lname"
              name="lastname"
              placeholder="Nachname"
            />

            <label>Email</label>
            <input
              onChange={(e) => handleChange(e, "email")}
              type="email"
              id="email"
              name="email"
              placeholder="Deine E-Mail"
            />

            <label>Deine Nachricht an uns</label>
            <textarea
              onChange={(e) => handleChange(e, "message")}
              id="message"
              name="message"
            ></textarea>

            <button
              type="submit"
              value="Kennenlernen"
              className="button--cta"
              onClick={(e) => handleFormSubmit(e)}
            >
              <span className="button--cta__text">Kennenlernen!</span>
            </button>
          </form>
        </div>
      </div> */}

      <div className="view--item view--bg-100 view--colors-dark">
        <div className="view--item__content">
          <p>
            <address class="contact__address">
              BLUE OYSTER
              <br /> Chrysostomou Smyrnis 24
              <br />
              Ilioupoli
              <br />
              16345 ATTICA
            </address>
          </p>
          <p>
            <FaRegEnvelope />
            &nbsp; &nbsp;
            <a href="mailto:blueoyster.dive@gmail.com">
              blueoyster.dive@gmail.com
            </a>
            <br />
            <FaPhoneAlt />
            &nbsp; &nbsp;
            <a href="tel:00306972295891">(+30) 6972295891</a>
            <br />
            <FaInstagram />
            &nbsp; &nbsp;
            <a href="https://www.instagram.com/blueoysterdive">
              @blueoysterdive
            </a>
            <br />
            <FaExternalLinkAlt />
            &nbsp; &nbsp;
            <a rhef="https://www.padi.com/dive-center/greece/blue-oyster-diving-center/">
              Blue Oyster at PADI.com
            </a>
            <br />
          </p>
        </div>
      </div>
    </div>
  );
}

export default Contact;
