import React from "react";
import { render } from "react-snapshot";
import Main from "./Main";
import { inject } from "@vercel/analytics";

// import i18n (needs to be bundled ;))
import "./i18n";
import { CookiesProvider } from "react-cookie";

inject();
// import * as serviceWorker
// import * as serviceWorker from "./serviceWorker";

render(
  <CookiesProvider defaultSetOptions={{ path: "/" }}>
    <Main />
  </CookiesProvider>,
  document.getElementById("root")
);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
