const HashScroll = () => {
	if (!window.location.hash) {
		window.scrollTo(0, 0);
	} else {
		let node = document.querySelector(window.location.hash);
		if (node && node.scrollIntoView) {
			node.scrollIntoView();
		}
	}
};

export default HashScroll;
