import React, { useState } from "react";

import {
  BrowserRouter as Router,
  Switch,
  Route,
  NavLink,
  Redirect,
} from "react-router-dom";
import { HashLink as Link } from "react-router-hash-link";
import { Helmet } from "react-helmet";
import { useTranslation, Trans } from "react-i18next";

function Home() {
  const { t, i18n } = useTranslation();
  const lang = i18n.resolvedLanguage || i18n.defaultLocale;
  return (
    <div id="page.home.">
      <Helmet htmlAttributes={{ lang }}>
        <title>
          {t("base.title")} - {t("navigation.Start")}
        </title>
        <meta
          name="description"
          content="BLUE OYSTER Marine Department. We have you covered."
        />
        <meta property="og:title" content="BLUE OYSTER. MARINE DEPARTMENT." />
        <meta
          property="og:description"
          content="BLUE OYSTER Marine Department. We have you covered."
        />
      </Helmet>
      <div className="view--item view--home">
        <div className="view--item__content">
          <h1>
            <span className="fx--home__line">
              {t("page.home.Hero.LineOne")}
            </span>
            <span className="fx--home__line">
              {t("page.home.Hero.LineTwo")}
            </span>
            <span className="view--item__spacer"></span>
            <span className="fx--home__line">
              {t("page.home.Hero.LineThree")}
            </span>
            <span className="fx--home__line">
              {t("page.home.Hero.LineFour")}
            </span>
            <span className="fx--home__line">
              {t("page.home.Hero.LineFive")}
            </span>
          </h1>
          <p className="fx--home__line">
            <Trans
              i18nKey="page.home.Hero.Teaser"
              components={{
                LinkPADI: <a href="http://https://blueoysterdive.gr/" />,
                bold: <b></b>,
              }}
            />
            <br />
          </p>
          <div className="button-group  fx--home__line">
            <Link to="#contact" className="button--cta">
              <span className="button--cta__text">{t("button.ContactUs")}</span>
            </Link>
          </div>

          <button className="button--scroll-down fx--home__down">
            <span className="button--scroll-down__text">
              {t("button.CTAHome")}
            </span>
            <svg
              className="button--scroll-down__icon"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 404.3 404.3"
              fill="#ffffff"
            >
              <path
                d="M290 18L266 0 114 202l152 202 24-18-138-184z"
                stroke="#ffffff"
              />
            </svg>
          </button>
        </div>
      </div>
    </div>
  );
}

export default Home;
