import React, { useState, useEffect } from "react";

import {
  BrowserRouter as Router,
  Switch,
  Route,
  NavLink,
  Redirect,
} from "react-router-dom";
import { HashLink as Link } from "react-router-hash-link";

import hashScroll from "./hashscroll";
import { Helmet } from "react-helmet";

function Imprint() {
  useEffect(() => {
    hashScroll();
  });
  return (
    <div id="imprint">
      <Helmet>
        <title>
          BLUE OYSTER DIVING CENTER ATHENS. PADI COURSES & MARINE DEPARTMENT.
        </title>
        <meta
          name="description"
          content="PADI certification, fun diving and experienced marine department. We have you covered."
        />
        <meta
          property="og:title"
          content="BLUE OYSTER DIVING CENTER ATHENS. PADI COURSES & MARINE DEPARTMENT."
        />
        <meta
          property="og:description"
          content="PADI certification, fun diving and experienced marine department. We have you covered."
        />
      </Helmet>
      <div className="view--item view--hero">
        <div className="view--item__content"></div>
      </div>
      <div className="view--item view--dark" id="strategie">
        <div className="view--item__content"></div>
      </div>

      <div className="view--item view--light" id="design">
        <div className="view--item__content"></div>
      </div>
      <div className="view--item view--medium" id="cms">
        <div className="view--item__content"></div>
      </div>
    </div>
  );
}

export default Imprint;
